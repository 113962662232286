import React from "react";
import { graphql } from "gatsby";
import PostsView from "@views/PostsView";

import Layout from "../ui/layout";

const TemplatesPage = (props: any) => {
  const posts = props?.data?.allMarkdownRemark?.edges?.map(
    (post: any) => post?.node,
  );

  return (
    <Layout
      header={{ color: "black" }}
      seo={{
        title: "Blog",
        description:
          "Reactemplates Blog. Search through a variety of React Templates that contains technologies like styled-components, Typescript, Gatsby, Next.js etc",
      }}
    >
      <PostsView posts={posts} />
    </Layout>
  );
};

export const query = graphql`
  query PostsPageTemplate {
    allMarkdownRemark(
      sort: { order: DESC, fields: frontmatter___createdat }
    ) {
      edges {
        node {
          id
          frontmatter {
            description
            title
            main_image {
              childImageSharp {
                fluid(maxWidth: 560, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          excerpt
          fields {
            slug
          }
        }
      }
    }
  }
`;

export default TemplatesPage;
